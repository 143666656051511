@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
main{
  height: 90vh;
}

code {
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 1400px) {
  .about {
    margin-top: "80vh"!important
  }
  main{
    height: 110vh !important;
  }
  .me {
  font-size: 5em !important;
  }
  
}
@media only screen and (max-width: 1280px) {
      main{
        height: auto !important;
      }
  .full{
    width: 100% !important;
  }
    .me {
    font-size: 3em !important;
  }
  .chevrom{
    margin-top: 80vh;
  }
  .half{
    width: 85% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
  }
}